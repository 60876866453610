import { useEffect, useState } from 'react'

export enum WINDOW_MODE {
  'XSM' = 'xsm',
  'SM' = 'sm',
  'MD' = 'md',
  'LG' = 'lg',
  'XL' = 'xl',
  '2XL' = '2xl',
  '3XL' = '3xl',
}

function useDetectWindowMode() {
  const [windowSize, setWindowSize] = useState<any>('')

  const handleSize = () => {
    let _size: string = ''
    if (window.innerWidth < 576) _size = WINDOW_MODE.XSM
    if (window.innerWidth >= 576) _size = WINDOW_MODE.SM
    if (window.innerWidth >= 768) _size = WINDOW_MODE.MD
    if (window.innerWidth >= 960) _size = WINDOW_MODE.LG
    if (window.innerWidth >= 1280) _size = WINDOW_MODE.XL
    if (window.innerWidth >= 1440) _size = WINDOW_MODE['2XL']
    if (window.innerWidth > 1920) _size = WINDOW_MODE['3XL']
    setWindowSize(_size)
  }

  useEffect(() => {
    if (typeof window !== undefined) {
      handleSize() // when component is load
      window.addEventListener('resize', handleSize)
      return () => {
        window.removeEventListener('resize', handleSize)
      }
    }
  }, [])

  return windowSize
}

export default useDetectWindowMode
