import { signIn, signOut, useSession, getSession } from 'next-auth/react'
import { useRouter } from 'next/router'
import React, { useEffect, ReactNode, useState } from 'react'
import Error from 'next/error'

type Props = {
  children: JSX.Element
}

const CreatorAuthGuard: React.FC<Props> = ({ children }) => {
  const router = useRouter()
  const { data: session, status } = useSession()
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    const securePage = async () => {
      const session = await getSession()
      if (!session) {
        signIn()
      } else if (session && !session.user.id) {
        signOut({ redirect: false }).then(() => {
          router.push('/login')
        })
      } else {
        setLoaded(true)
      }
    }
    securePage()
  }, [])

  if (loaded && session) {
    if (
      session?.user?.roles.includes('creator') ||
      session?.user?.roles.includes('admin')
    ) {
      return children
    } else {
      return <Error statusCode={404} title="page Not Found" />
    }
  }

  return null
}

export default CreatorAuthGuard
